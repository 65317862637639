.app {
  display: flex;
  justify-content: center;
  text-align: center;
}
a {
  text-decoration: none;
  transition: color .1s ease-in-out;
  -webkit-transition: color .1s ease-in-out;
  -o-transition: color .1s ease-in-out;
  -moz-transition: color .1s ease-in-out;
  -ms-transition: color .1s ease-in-out;
}
.todo_input {
  height: 16px;
  padding: 10px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border: 2px solid blueviolet;
}

.todo_button {
  border: 2px solid blueviolet;
  background-color: transparent;
  height: 40px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.todo {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  font-weight: 700;
  margin-bottom: 5px;
  min-width: 340px;
}

.edit {
  width: 66px;
  font-weight: 700;
  background: blueviolet;
  border: none;
  border-top-right-radius: 5px;
  height: 33px;
  border-bottom-right-radius: 5px;
  color: white;
  font-size: medium;
}

.delete {
  width: 66px;
  font-weight: 700;
  background: white;
  border: 2px solid blueviolet;
  border-top-left-radius: 5px;
  height: 33px;
  color: blueviolet;
  border-bottom-left-radius: 5px;
  font-size: medium;
}

.form {
  padding-top: 27px;
  padding-bottom: 27px;
}

.name {
  max-width: 190.34px;
  text-align: left;
}
.races-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.margin-t {
  margin-top: 2rem;
}
.container {
  margin: auto;
}

.section-title {
  text-align: center;
  margin-bottom: 1.5rem;
  color: #031856;
  font-weight: 700;
  position: relative;
}
.row.space-around {
  justify-content: space-around;
}
.row.no-gutters {
  margin-left: 0;
  margin-right: 0;
}
.race-item  {
  margin-bottom: 20px;
  box-sizing: border-box;
  display: block;
  transition: transform .2s ease-in-out;
  -webkit-transition: transform .2s ease-in-out;
  -o-transition: transform .2s ease-in-out;
  -moz-transition: transform .2s ease-in-out;
  -ms-transition: transform .2s ease-in-out;
  transform-origin: center bottom;
}
.race-item>div {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.badge {
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-color: #777777;
  border-radius: 10px;
}
.badge+.badge {
  margin-right: 5px;
}
.race-item .race-info .race-distances .badge-distance {
  padding: 6px 10px;
  border-radius: 16px;
  background: #fff;
  border: 1px solid #ff7468;
  color: #031856;
  display: inline;
  align-items: center;
  margin-bottom: 4px;
}
.race-item .race-info .race-distances .badge-type-sport {
  border-color: #4a8ecc;
}

.race-item {
  margin-bottom: 20px;
  box-sizing: border-box;
  display: block;
  transition: transform .2s ease-in-out;
  -webkit-transition: transform .2s ease-in-out;
  -o-transition: transform .2s ease-in-out;
  -moz-transition: transform .2s ease-in-out;
  -ms-transition: transform .2s ease-in-out;
  transform-origin: center bottom;
}
.cover-banner {
  max-width: 500px;
  margin: 5px auto;
}