.div {
    width: 100%;
  }
  @media (max-width: 991px) {
    .div {
      max-width: 100%;
    }
  }
  .div-2 {
    gap: 20px;
    display: flex;
  }
  @media (max-width: 991px) {
    .div-2 {
      flex-direction: column;
      align-items: stretch;
      gap: 0px;
    }
  }
  .column {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 50%;
    margin-left: 0px;
  }
  @media (max-width: 991px) {
    .column {
      width: 100%;
    }
  }
  .div-3 {
    disply: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    display: flex;
    min-height: 512px;
    flex-grow: 1;
    align-items: end;
    padding: 46px 0;
  }
  @media (max-width: 991px) {
    .div-3 {
      max-width: 100%;
    }
  }
  .img {
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
  .div-4 {
    position: relative;
    display: flex;
    width: 538px;
    max-width: 100%;
    flex-direction: column;
    text-transform: uppercase;
  }
  @media (max-width: 991px) {
    .div-4 {
      margin-right: 10px;
    }
  }
  .div-5 {
    align-self: start;
    display: flex;
    gap: 15px;
    font-size: 36px;
    color: var(--Primary-Color, #ae0b60);
    font-weight: 800;
  }
  .img-2 {
    aspect-ratio: 1.06;
    object-fit: auto;
    object-position: center;
    width: 120px;
    height: 50px;
  }
  .div-6 {
    font-family: Lato, sans-serif;
    flex-grow: 1;
    flex-basis: auto;
    margin: auto 0;
  }
  .div-7 {
    color: var(--Black, #212121);
    letter-spacing: 5.4px;
    align-self: end;
    margin-top: 125px;
    font: 700 18px/122% Poppins, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  @media (max-width: 991px) {
    .div-7 {
      max-width: 100%;
      margin-top: 40px;
    }
  }
  .div-8 {
    position: relative;
    border-color: rgba(241, 128, 42, 1);
    border-style: solid;
    border-width: 1px;
    background-color: #f1802a;
    align-self: stretch;
    margin-top: 5px;
    height: 1px;
  }
  @media (max-width: 991px) {
    .div-8 {
      max-width: 100%;
    }
  }
  .div-9 {
    position: relative;
    display: flex;
    margin-top: 31px;
    width: 631px;
    max-width: 100%;
    flex-direction: column;
    padding: 0 20px;
  }
  .div-10 {
    color: var(--Primary-Color, #ae0b60);
    font: 700 42px/50px Poppins, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  @media (max-width: 991px) {
    .div-10 {
      max-width: 100%;
    }
  }
  .div-11 {
    color: var(--Black, #212121);
    margin-top: 40px;
    font: 400 16px/24px Lato, sans-serif;
  }
  @media (max-width: 991px) {
    .div-11 {
      max-width: 100%;
    }
  }
  .column-2 {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 50%;
    margin-left: 20px;
  }
  @media (max-width: 991px) {
    .column-2 {
      width: 100%;
    }
  }
  .div-12 {
    border-radius: 35px 0px 0px 35px;
    background-color: var(--Primary-Color, #ae0b60);
    display: flex;
    width: 100%;
    align-items: start;
    gap: 20px;
    font-size: 18px;
    color: var(--White, #fff);
    font-weight: 700;
    white-space: nowrap;
    line-height: 122%;
    margin: 40px auto 0;
    padding: 20px 80px 0;
  }
  @media (max-width: 991px) {
    .div-12 {
      max-width: 100%;
      flex-wrap: wrap;
      white-space: initial;
      padding: 0 20px;
    }
  }
  .div-13 {
    display: flex;
    margin-top: 6px;
    align-items: start;
    gap: 20px;
    justify-content: space-between;
  }
  @media (max-width: 991px) {
    .div-13 {
      white-space: initial;
    }
  }
  .div-14 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
  }
  @media (max-width: 991px) {
    .div-14 {
      white-space: initial;
    }
  }
  .div-15 {
    font-family: Poppins, sans-serif;
    align-self: center;
  }
  .div-16 {
    border-radius: 1.5px 1.5px 0px 0px;
    background-color: var(--Secondary-Color, #f1802a);
    margin-top: 28px;
    height: 3px;
  }
  .div-17 {
    font-family: Poppins, sans-serif;
  }
  .div-18 {
    font-family: Poppins, sans-serif;
  }
  .div-19 {
    align-self: stretch;
    display: flex;
    gap: 9px;
    margin: auto 0;
  }
  @media (max-width: 991px) {
    .div-19 {
      white-space: initial;
    }
  }
  .div-20 {
    font-family: Poppins, sans-serif;
  }
  .img-3 {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 8px;
    fill: var(--White, #fff);
    margin: auto 0;
  }
  .img-4 {
    aspect-ratio: 0.83;
    object-fit: auto;
    object-position: center;
    width: 25px;
  }